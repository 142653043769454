import { config, EyesiteBootConfig } from '@eyesite/runtime';
import { PluginState } from '@eyesite/data';
// Legacy binding paths
export { config, EyesiteBootConfig as Settings };

let eyesiteConfig: EyesiteBootConfig = config;

export default eyesiteConfig;

export const getConfig = () => {
  return eyesiteConfig;
};

export const updateConfig = (update: Partial<EyesiteBootConfig>) => {
  eyesiteConfig = {
    ...eyesiteConfig,
    ...update,
  };
};

// The `enable_alpha` flag is no exposed directly, this is equivolant
export const hasAlphaPanels = Boolean(config.panels?.debug?.state === PluginState.alpha);
