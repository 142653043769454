import { DataSourceInstanceSettings } from './datasource';
import { PanelPluginMeta } from './panel';
import { EyesiteTheme } from './theme';
import { SystemDateFormatSettings } from '../datetime';
import { EyesiteTheme2 } from '../themes';
import { MapLayerOptions } from '../geo/layer';

/**
 * Describes the build information that will be available via the Eyesite configuration.
 *
 * @public
 */
export interface BuildInfo {
  version: string;
  commit: string;
  /**
   * Is set to true when running Eyesite Enterprise edition.
   *
   * @deprecated use `licenseInfo.hasLicense` instead
   */
  isEnterprise: boolean;
  env: string;
  edition: EyesiteEdition;
  latestVersion: string;
  hasUpdate: boolean;
  hideVersion: boolean;
}

/**
 * @internal
 */
export enum EyesiteEdition {
  OpenSource = 'Open Source',
  Pro = 'Pro',
  Enterprise = 'Enterprise',
}

/**
 * Describes available feature toggles in Eyesite. These can be configured via the
 * `conf/custom.ini` to enable features under development or not yet available in
 * stable version.
 *
 * @public
 */
export interface FeatureToggles {
  [name: string]: boolean;

  trimDefaults: boolean;
  accesscontrol: boolean;
  tempoServiceGraph: boolean;
  tempoSearch: boolean;
  recordedQueries: boolean;
  newNavigation: boolean;
  fullRangeLogsVolume: boolean;
}

/**
 * Describes the license information about the current running instance of Eyesite.
 *
 * @public
 */
export interface LicenseInfo {
  hasLicense: boolean;
  expiry: number;
  licenseUrl: string;
  stateInfo: string;
  hasValidLicense: boolean;
  edition: EyesiteEdition;
}

/**
 * Describes Sentry integration config
 *
 * @public
 */
export interface SentryConfig {
  enabled: boolean;
  dsn: string;
  customEndpoint: string;
  sampleRate: number;
}

/**
 * Describes the plugins that should be preloaded prior to start Eyesite.
 *
 * @public
 */
export type PreloadPlugin = {
  path: string;
  version: string;
};

/**
 * Describes all the different Eyesite configuration values available for an instance.
 *
 * @public
 */
export interface EyesiteConfig {
  datasources: { [str: string]: DataSourceInstanceSettings };
  panels: { [key: string]: PanelPluginMeta };
  minRefreshInterval: string;
  appSubUrl: string;
  windowTitlePrefix: string;
  buildInfo: BuildInfo;
  newPanelTitle: string;
  bootData: any;
  externalUserMngLinkUrl: string;
  externalUserMngLinkName: string;
  externalUserMngInfo: string;
  allowOrgCreate: boolean;
  disableLoginForm: boolean;
  defaultDatasource: string;
  alertingEnabled: boolean;
  alertingErrorOrTimeout: string;
  alertingNoDataOrNullValues: string;
  alertingMinInterval: number;
  authProxyEnabled: boolean;
  exploreEnabled: boolean;
  ldapEnabled: boolean;
  sigV4AuthEnabled: boolean;
  samlEnabled: boolean;
  autoAssignOrg: boolean;
  verifyEmailEnabled: boolean;
  oauth: any;
  disableUserSignUp: boolean;
  loginHint: any;
  passwordHint: any;
  loginError: any;
  navTree: any;
  viewersCanEdit: boolean;
  editorsCanAdmin: boolean;
  disableSanitizeHtml: boolean;
  liveEnabled: boolean;
  theme: EyesiteTheme;
  theme2: EyesiteTheme2;
  pluginsToPreload: PreloadPlugin[];
  featureToggles: FeatureToggles;
  licenseInfo: LicenseInfo;
  http2Enabled: boolean;
  dateFormats?: SystemDateFormatSettings;
  sentry: SentryConfig;
  customTheme?: any;
  geomapDefaultBaseLayer?: MapLayerOptions;
  geomapDisableCustomBaseLayer?: boolean;
  unifiedAlertingEnabled: boolean;
}
