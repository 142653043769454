import React from 'react';

export const AngularRoot = React.forwardRef<HTMLDivElement, {}>((props, ref) => {
  return (
    <div
      id="ngRoot"
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: '<eyesite-app ng-cloak></eyesite-app>',
      }}
    />
  );
});

AngularRoot.displayName = 'AngularRoot';
