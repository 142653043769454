import angular from 'angular';

const coreModule = angular.module('eyesite.core', ['ngRoute']);

// legacy modules
const angularModules = [
  coreModule,
  angular.module('eyesite.controllers', []),
  angular.module('eyesite.directives', []),
  angular.module('eyesite.factories', []),
  angular.module('eyesite.services', []),
  angular.module('eyesite.filters', []),
  angular.module('eyesite.routes', []),
];

export { angularModules, coreModule };

export default coreModule;
