import { map, sortBy, flattenDeep, chunk, zip } from 'lodash';
import tinycolor from 'tinycolor2';

const PALETTE_ROWS = 4;

/**
 * @alpha
 */
export const DEFAULT_ANNOTATION_COLOR = 'rgba(0, 211, 255, 1)';

/**
 * @alpha
 */
export const OK_COLOR = 'rgba(11, 237, 50, 1)';

/**
 * @alpha
 */
export const ALERTING_COLOR = 'rgba(237, 46, 24, 1)';

/**
 * @alpha
 */
export const NO_DATA_COLOR = 'rgba(150, 150, 150, 1)';

/**
 * @alpha
 */
export const PENDING_COLOR = 'rgba(247, 149, 32, 1)';

/**
 * @alpha
 */
export const REGION_FILL_ALPHA = 0.09;
export const colors = [
  '#F2495C',
  '#C34A36',
  '#DE4499',
  '#A05DC8',
  '#24819C',
  '#007CC9',
  '#007B9A',
  '#FE2B62',
  '#00896F',
  '#008CCB',
  '#FA6400',
  '#00843C',
  '#00807C',
  '#D5388B',
  '#00A595',
  '#009AAE',
  '#675DB3',
  '#007062',
  '#2C80BE',
  '#DF4B65',
  '#2C73D2',
  '#00C9A7',
  '#8F3BB8',
  '#FF9830',
  '#00E9B7',
  '#3B6896',
  '#38837C',
  '#E02F44',
  '#C4162A',
  '#008A5E',
];

function sortColorsByHue(hexColors: string[]) {
  const hslColors = map(hexColors, hexToHsl);

  const sortedHSLColors = sortBy(hslColors, ['h']);
  const chunkedHSLColors = chunk(sortedHSLColors, PALETTE_ROWS);
  const sortedChunkedHSLColors = map(chunkedHSLColors, (chunk) => {
    return sortBy(chunk, 'l');
  });
  const flattenedZippedSortedChunkedHSLColors = flattenDeep(zip(...sortedChunkedHSLColors));

  return map(flattenedZippedSortedChunkedHSLColors, hslToHex);
}

function hexToHsl(color: string) {
  return tinycolor(color).toHsl();
}

function hslToHex(color: any) {
  return tinycolor(color).toHexString();
}

export function getTextColorForBackground(color: string) {
  const b = tinycolor(color).getBrightness();
  return b > 180 ? 'rgb(32, 34, 38)' : 'rgb(247, 248, 250)';
}

export let sortedColors = sortColorsByHue(colors);
